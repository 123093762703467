// # SHORT HOWTO
// ## Sprites
//     .whatever
// 			+s(codedby) 
// ## gradient example
//     	+g(#232323, #5c5c5c);
//	   horizontal
//     	+gh(#232323, #5c5c5c);
// ## making bw-images
//     img
//			@extend %gray
// ## predefined media-queries: https://github.com/paranoida/sass-mediaqueries
//     +r(320)
//			display: none
// +max-screen(1000), +iphone5, +iphone4, +ipad-retina, +ipad, +hdpi - also available
//
// ## use cycles
//     @for $i from 1 through 3
//       .item-#{$i}
//			width: 2em * $i;
//     @each $s in a,b,c
// 	    	#{$s}
//				display: none
// ## fonts
// Save fonts to /src/fonts
// +font(book, cytiapro-black-webfont)
// and create mixin
// =book
// 	font-family: "book", Arial, sans-serif

body
	//font-family: 'Roboto', sans-serif
	font-size: 12px
	line-height: 1.4



