.slick-slider
	position: relative
	display: block
	box-sizing: border-box
	-moz-box-sizing: border-box
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none
	-ms-touch-action: pan-y
	touch-action: pan-y
	-webkit-tap-highlight-color: transparent
.slick-list
	position: relative
	overflow: hidden
	display: block
	margin: 0
	padding: 0
	&:focus
		outline: none
	.slick-loading &
		background: white
	&.dragging
		cursor: pointer
		cursor: hand

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img
	transform: translate3d(0, 0, 0)
.slick-track
	position: relative
	left: 0
	top: 0
	display: block
	zoom: 1
	&:before,
	&:after
		content: ""
		display: table
	&:after
		clear: both
	.slick-loading &
		visibility: hidden

.slick-slide
	float: left
	height: 100%
	min-height: 1px
	img
		display: block
		max-width: 100%
	&.slick-loading img
		display: none
	&.dragging img
		pointer-events: none
	.slick-initialized &
		display: block
	.slick-loading &
		visibility: hidden
	.slick-vertical &
		display: block
		height: auto
		border: 1px solid transparent

.slick-prev,
.slick-next
	position: absolute
	top: 50%
	margin-top: -10px
	+hide-text
	height: 20px
	background: none
	left: 0
	z-index: 1 
	&:focus
		outline: none
	&.slick-disabled
		opacity: 0.2
	&:before
		height: 30px
		line-height: 30px
		content: "prev"
		display: inline-block
		vertical-align: middle
		font-size: 15px
		color: #fff
		background: #000 
.slick-next
	right: 0
	left: auto
	&:before
		content: "next"
		display: inline-block
.slick-dots
	text-align: center
	position: absolute
	margin: 0
	bottom: 0
	left: 0
	right: 0
	li
		position: relative
		display: inline-block
		padding: 0 3px
		&.slick-active
			button
				background: #000 
	button
		display: block
		width: 10px
		height: 10px
		background: #fff
		box-shadow: 0 1px 5px rgba(0,0,0,.25)
		cursor: pointer
		+hide-text
		border-radius: 50%
