$primary: #4D4D4D
$sec: #123726
$third: #f09819
$fourth: #00aeef
$red: #BA4C38
$desc: 1000


a
	text-decoration: none
	color: inherit

body
	font-family: 'Roboto', sans-serif

=flex($hor:space-between, $vert:center)
	display: flex
	flex-wrap: wrap
	justify-content: $hor
	align-items: center

=shadow
	box-shadow: 2px 2px 2px gray
=bora
	border-radius: 5px
*
	transition: hover .25s
	&::selection
		background: #BA4C38
		color: white

.inner
	width: 1000px
	margin: 0 auto
	position: relative
	padding: 0 10px
	min-width: 768px
	+clr
	+r($desc)
		width: 100%
.header
	background: $primary
	background: linear-gradient(to bottom, $primary 0, darken($primary, 5%), $primary 100%)
	min-width: 768px
	.inner
		+flex
		&:after
			display: none
	&__logo
		text-align: center
		float: left
		color: white
		font-weight: bold
		font-size: 30px
		margin-top: 4px

		span
			font-family: 'Libre Baskerville', serif
			text-shadow: 0 0 45px $red

			b
				vertical-align: middle
				display: inline-block
				width: 40px
				height: 40px
				background: url("../img/play-4.svg")
				background-size: cover



.menu
	height: 50px
	line-height: 50px
	&__item
		display: inline-block
		vertical-align: middle
		margin-right: 15px
		color: white
		font-size: 16px
		position: relative
		&:last-of-type
			margin-right: 0
		&.has-dropdown
			&:after
				display: inline-block
				content: ''
				margin-left: -5px
				vertical-align: middle
				+s(arr-down)

	&__link
		height: 50px
		display: block
		border-bottom: 3px solid transparent
		&:hover
			border-bottom-color: $third
			border-bottom-color: #BA4C38


	&__submenu
		position: absolute
		z-index: 100
		//background: lighten($primary, 5%)
		top: 49px
		left: 0
		width: 100%
		a
			background: #BA4C38
			background: $primary
			//background: #843B1B
			display: block
			padding: 6px 20px
			line-height: 1.4
			//border-top: 2px solid darken($primary, 4%)
			&:hover
				background: $red
				//background: $sec

.is-hidden
	display: none


.content
	background: url("../img/bg-2.jpg") center center no-repeat fixed
	background-size: cover
	min-width: 768px
	padding: 20px 0 40px 0
	p
		font-size: 14px
		color: white
		margin-top: 15px
		text-shadow: 1px 1px 1px black
		a
			color: $red
			text-decoration: underline
			font-weight: bold
	h1
		color: white
		font-size: 36px
		font-size: 30px
		font-weight: bold
		text-shadow: 1px 1px 1px black
		font-style: italic
		border-bottom: 2px solid white
	h2
		font-size: 20px
		margin-top: 15px
		color: white
		text-transform: capitalize
		text-shadow: 1px 1px 1px black
		font-style: italic
		border-bottom: 2px solid white


	ul
		margin-top: 15px
		margin-bottom: 15px
		list-style: square !important
		list-style-position: inside !important
		li
			color: white
			font-size: 14px
			margin-top: 5px
			text-shadow: 1px 1px 1px black
	&__img
		float: left
		margin: 15px 15px 15px 0
		&:nth-of-type(even)
			float: right
			margin: 15px 0 15px 15px

.center
	text-align: center
.disclosure
	margin-top: 20px
	+flex
	&__benefits
		p
			text-transform: uppercase
			margin-top: 0
			span
				margin-left: 15px
				&:before
					content: ''
					margin-right: 5px
					display: inline-block
					vertical-align: middle
					+s(money)

				&:first-of-type:before
					+s(gb-flag)
				&:last-of-type:before
					+s(like-2)
					position: relative
					top: -4px
	&__main
		position: relative
		top: -7px
	&__popup-trigger
		cursor: pointer
		&:hover
			text-decoration: underline
	&__popup
		background: lightgrey
		padding: 20px 15px
		box-shadow: 2px 2px 2px black
		width: 325px
		position: absolute
		right: -190px
		top: 50px
		border: 2px dashed $primary
		display: none
		+r($desc)
			right: -195px
			transform: none
		&.is-visible
			display: block
		p
			text-transform: capitalize
			color: #636161
			text-shadow: none
		strong
			display: block
			text-align: center
		span
			+s(close)
			display: block
			position: absolute
			right: 10px
			top: 10px
			cursor: pointer


.rating
	margin-bottom: 25px
	&__top
		background: $sec
		height: 40px
		line-height: 40px
		color: white
		font-size: 18px
		text-transform: uppercase
		+clr


	&__col
		text-align: center
		float: left
		//outline: 1px solid red
		&_1
			width: 24%
			img
				max-width: 100%
				display: block
		&_2
			width: 27.9%
			span
				font-size: 13px
				display: block
				margin-top: 20px
				+r($desc)
					margin-top: 10px
			a
				font-size: 18px
				&:hover
					text-decoration: underline
		&_3
			width: 15.7%

			strong
				font-weight: normal
				display: block
				font-size: 64px
				color: gray
				margin-top: 10px
				+r($desc)
					font-size: 50px
			span
				display: inline-block
				+s(star)
				&.halve
					+s(half-star)


		&_4
			width: 14.3%
			span
				display: block
				font-size: 64px
				color: $fourth
				color: $primary
				margin-top: 10px
				+r($desc)
					font-size: 50px
		&_5
			width: 17.8%
			.rating__get-bonus
				margin-top: 14px
				margin-bottom: 10px


	&__link
		text-decoration: underline
		color: $sec
	&__row
		padding: 20px 0
		background: white
		+clr
		&:nth-of-type(odd)
			background: #F2F2F2
			background: lightgrey
		.rating__col_1
			padding-left: 15px
		.rating__col_5
			padding-right: 15px
		&:hover
			.rating__col_1 img
				opacity: .8
			.rating__col_5 .rating__get-bonus
				//background: linear-gradient(to bottom,#f8ef4f 0,#f2ac44 100%)
				background: #f2ac48
.btn
	display: block
	height: 53px
	line-height: 54px
	background: #BA4C38
	font-size: 28px
	text-align: center
	box-shadow: 2px 2px 2px gray
	border-radius: 5px
	color: white
	transition: .5s
	+r($desc)
		font-size: 18px
	&:hover
		background: #f2ac48
	&:active
		transform: scale(.95)
	&_small
		width: 250px
		+r($desc)
			width: 200px
	&_center
		margin: 15px auto


.footer
	background: linear-gradient(to bottom, $primary 0, darken($primary, 5%), $primary 100%)
	padding-top: 30px
	padding-bottom: 10px
	min-width: 768px
	&__nav
		+flex(center, center)
		margin-bottom: 15px
		li
			color: white
			font-size: 12px
			margin-right: 15px

			&:last-of-type
				margin-right: 0
				&:after
					display: none
		a
			@extend .btn
			box-shadow: none
			height: auto
			line-height: 1
			font-size: 14px
			padding: 5px 10px
			margin-bottom: 10px
			+r($desc)
				font-size: 14px


	small
		font-size: 12px
		color: white
		display: block
		text-align: center
		margin: 3px 0


	&__logo
		float: left
		margin-right: 50px
		@extend .header__logo


.block
	+clr
	+bora
	overflow: hidden
	padding: 20px 15px
	background: lightgray
	background: rgba(lightgray, .8)
	margin-top: 20px

	&__content
		width: 70%
		padding: 10px 15px 20px 15px

		border: 2px dashed $primary
		float: right
		+bora
	p
		color: black
		text-shadow: none
	h2
		color: $primary
		text-shadow: none
		margin-top: 15px
		border-bottom: none
		&:first-of-type
			margin-top: 0
	ul
		li
			color: black
			text-shadow: none


.sidebar
	float: left
	width: 30%
	padding-right: 15px
	p
		margin-top: 0
	&__banner
		display: block
		+shadow
		+bora
		overflow: hidden
		img
			max-width: 100%
			display: block
	&__rating
		margin-top: 15px
		+shadow
		+bora
		overflow: hidden
		img
			max-width: 100%
			display: block
	&__rating-bottom
		padding-top: 10px
		padding-bottom: 10px
		border-bottom: 1px solid $primary
		background: white
		+clr
	&__rating-left, &__rating-right
		float: left
		text-align: center
	&__rating-left
		width: 60%
		padding-top: 13px
		span
			display: inline-block
			+s(star)
	&__rating-right
		width: 40%
		border-left: 1px solid $primary

		span
			font-size: 30px
			color: $primary
	&__btn
		margin-top: 15px
		margin-bottom: 15px

	&__top
		box-shadow: 2px 2px 2px gray
		border-radius: 5px
		overflow: hidden
		background: white
	&__top-intro
		background: $primary
		color: white
		font-size: 16px
		text-align: center
		padding: 5px 0
		border-top-left-radius: 5px
		border-top-right-radius: 5px

	&__top-item
		margin-top: 5px
		padding-right: 5px
		padding-left: 5px
		+clr
		&:last-of-type
			border-bottom-left-radius: 5px
			border-bottom-right-radius: 5px
			padding-bottom: 5px
		img
			display: block
			float: left
			max-width: 130px
			width: 100%
			+r($desc)
				float: none
				margin: 0 auto
				max-width: 100%
		div
			float: right
			+r($desc)
				float: none
				text-align: center
				padding: 0 20px
			strong
				display: block
				margin-top: 5px
				margin-bottom: 5px
				font-size: 14px
				color: $primary
			span
				display: inline-block
				vertical-align: middle
				+s(star-small)
				&:first-of-type
					margin-left: 5px
			p
				margin-bottom: 5px
			a
				text-decoration: underline
				color: $red
				float: left
				display: inline-block
				margin: 0 3px
				font-size: initial

				&:last-of-type
					float: right

