@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

body, html { height: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

input[type="submit"], button { cursor: pointer; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) { * { -webkit-text-size-adjust: none; } }
table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@font-face { font-family: "svgfont"; src: url("fonts/svgfont.eot"); src: url("fonts/svgfont.eot?#iefix") format("eot"), url("fonts/svgfont.woff") format("woff"), url("fonts/svgfont.woff2") format("woff2"), url("fonts/svgfont.ttf") format("truetype"); font-weight: normal; font-style: normal; }
.icon, [class^="icon-"], [class*=" icon-"] { font-family: "svgfont"; font-style: normal; font-weight: normal; text-rendering: auto; speak: none; line-height: 1; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon, [class^="icon-"], [class*=" icon-"] { display: inline-block; }

.icon-sepa:before { content: ""; }

.icon-skrill:before { content: ""; }

.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-loading .slick-list { background: white; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; zoom: 1; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; }
.slick-slide img { display: block; max-width: 100%; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-prev, .slick-next { position: absolute; top: 50%; margin-top: -10px; font: 0/0 a; text-shadow: none; color: transparent; height: 20px; background: none; left: 0; z-index: 1; }
.slick-prev:focus, .slick-next:focus { outline: none; }
.slick-prev.slick-disabled, .slick-next.slick-disabled { opacity: 0.2; }
.slick-prev:before, .slick-next:before { height: 30px; line-height: 30px; content: "prev"; display: inline-block; vertical-align: middle; font-size: 15px; color: #fff; background: #000; }

.slick-next { right: 0; left: auto; }
.slick-next:before { content: "next"; display: inline-block; }

.slick-dots { text-align: center; position: absolute; margin: 0; bottom: 0; left: 0; right: 0; }
.slick-dots li { position: relative; display: inline-block; padding: 0 3px; }
.slick-dots li.slick-active button { background: #000; }
.slick-dots button { display: block; width: 10px; height: 10px; background: #fff; box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25); cursor: pointer; font: 0/0 a; text-shadow: none; color: transparent; border-radius: 50%; }

body { font-size: 12px; line-height: 1.4; }

a { text-decoration: none; color: inherit; }

body { font-family: "Roboto", sans-serif; }

* { transition: hover 0.25s; }
*::selection { background: #BA4C38; color: white; }

.inner { width: 1000px; margin: 0 auto; position: relative; padding: 0 10px; min-width: 768px; *zoom: 1; }
.inner:after { content: " "; display: table; clear: both; }
@media only screen and (max-width: 1000px) { .inner { width: 100%; } }

.header { background: #4D4D4D; background: linear-gradient(to bottom, #4D4D4D 0, #404040, #4D4D4D 100%); min-width: 768px; }
.header .inner { display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; }
.header .inner:after { display: none; }
.header__logo, .footer__logo { text-align: center; float: left; color: white; font-weight: bold; font-size: 30px; margin-top: 4px; }
.header__logo span, .footer__logo span { font-family: "Libre Baskerville", serif; text-shadow: 0 0 45px #BA4C38; }
.header__logo span b, .footer__logo span b { vertical-align: middle; display: inline-block; width: 40px; height: 40px; background: url("../img/play-4.svg"); background-size: cover; }

.menu { height: 50px; line-height: 50px; }
.menu__item { display: inline-block; vertical-align: middle; margin-right: 15px; color: white; font-size: 16px; position: relative; }
.menu__item:last-of-type { margin-right: 0; }
.menu__item.has-dropdown:after { display: inline-block; content: ""; margin-left: -5px; vertical-align: middle; background-image: url("../img/icons.png"); width: 25px; height: 21px; background-position: -83px -56px; }
.menu__link { height: 50px; display: block; border-bottom: 3px solid transparent; }
.menu__link:hover { border-bottom-color: #f09819; border-bottom-color: #BA4C38; }
.menu__submenu { position: absolute; z-index: 100; top: 49px; left: 0; width: 100%; }
.menu__submenu a { background: #BA4C38; background: #4D4D4D; display: block; padding: 6px 20px; line-height: 1.4; }
.menu__submenu a:hover { background: #BA4C38; }

.is-hidden { display: none; }

.content { background: url("../img/bg-2.jpg") center center no-repeat fixed; background-size: cover; min-width: 768px; padding: 20px 0 40px 0; }
.content p { font-size: 14px; color: white; margin-top: 15px; text-shadow: 1px 1px 1px black; }
.content p a { color: #BA4C38; text-decoration: underline; font-weight: bold; }
.content h1 { color: white; font-size: 36px; font-size: 30px; font-weight: bold; text-shadow: 1px 1px 1px black; font-style: italic; border-bottom: 2px solid white; }
.content h2 { font-size: 20px; margin-top: 15px; color: white; text-transform: capitalize; text-shadow: 1px 1px 1px black; font-style: italic; border-bottom: 2px solid white; }
.content ul { margin-top: 15px; margin-bottom: 15px; list-style: square !important; list-style-position: inside !important; }
.content ul li { color: white; font-size: 14px; margin-top: 5px; text-shadow: 1px 1px 1px black; }
.content__img { float: left; margin: 15px 15px 15px 0; }
.content__img:nth-of-type(even) { float: right; margin: 15px 0 15px 15px; }

.center { text-align: center; }

.disclosure { margin-top: 20px; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; }
.disclosure__benefits p { text-transform: uppercase; margin-top: 0; }
.disclosure__benefits p span { margin-left: 15px; }
.disclosure__benefits p span:before { content: ""; margin-right: 5px; display: inline-block; vertical-align: middle; background-image: url("../img/icons.png"); width: 17px; height: 17px; background-position: -109px -118px; }
.disclosure__benefits p span:first-of-type:before { background-image: url("../img/icons.png"); width: 25px; height: 18px; background-position: -32px -118px; }
.disclosure__benefits p span:last-of-type:before { background-image: url("../img/icons.png"); width: 29px; height: 29px; background-position: -34px -84px; position: relative; top: -4px; }
.disclosure__main { position: relative; top: -7px; }
.disclosure__popup-trigger { cursor: pointer; }
.disclosure__popup-trigger:hover { text-decoration: underline; }
.disclosure__popup { background: lightgrey; padding: 20px 15px; box-shadow: 2px 2px 2px black; width: 325px; position: absolute; right: -190px; top: 50px; border: 2px dashed #4D4D4D; display: none; }
@media only screen and (max-width: 1000px) { .disclosure__popup { right: -195px; transform: none; } }
.disclosure__popup.is-visible { display: block; }
.disclosure__popup p { text-transform: capitalize; color: #636161; text-shadow: none; }
.disclosure__popup strong { display: block; text-align: center; }
.disclosure__popup span { background-image: url("../img/icons.png"); width: 30px; height: 30px; background-position: 0px -84px; display: block; position: absolute; right: 10px; top: 10px; cursor: pointer; }

.rating { margin-bottom: 25px; }
.rating__top { background: #123726; height: 40px; line-height: 40px; color: white; font-size: 18px; text-transform: uppercase; *zoom: 1; }
.rating__top:after { content: " "; display: table; clear: both; }
.rating__col { text-align: center; float: left; }
.rating__col_1 { width: 24%; }
.rating__col_1 img { max-width: 100%; display: block; }
.rating__col_2 { width: 27.9%; }
.rating__col_2 span { font-size: 13px; display: block; margin-top: 20px; }
@media only screen and (max-width: 1000px) { .rating__col_2 span { margin-top: 10px; } }
.rating__col_2 a { font-size: 18px; }
.rating__col_2 a:hover { text-decoration: underline; }
.rating__col_3 { width: 15.7%; }
.rating__col_3 strong { font-weight: normal; display: block; font-size: 64px; color: gray; margin-top: 10px; }
@media only screen and (max-width: 1000px) { .rating__col_3 strong { font-size: 50px; } }
.rating__col_3 span { display: inline-block; background-image: url("../img/icons.png"); width: 20px; height: 18px; background-position: -85px -118px; }
.rating__col_3 span.halve { background-image: url("../img/icons.png"); width: 20px; height: 18px; background-position: -61px -118px; }
.rating__col_4 { width: 14.3%; }
.rating__col_4 span { display: block; font-size: 64px; color: #00aeef; color: #4D4D4D; margin-top: 10px; }
@media only screen and (max-width: 1000px) { .rating__col_4 span { font-size: 50px; } }
.rating__col_5 { width: 17.8%; }
.rating__col_5 .rating__get-bonus { margin-top: 14px; margin-bottom: 10px; }
.rating__link { text-decoration: underline; color: #123726; }
.rating__row { padding: 20px 0; background: white; *zoom: 1; }
.rating__row:after { content: " "; display: table; clear: both; }
.rating__row:nth-of-type(odd) { background: #F2F2F2; background: lightgrey; }
.rating__row .rating__col_1 { padding-left: 15px; }
.rating__row .rating__col_5 { padding-right: 15px; }
.rating__row:hover .rating__col_1 img { opacity: 0.8; }
.rating__row:hover .rating__col_5 .rating__get-bonus { background: #f2ac48; }

.btn, .footer__nav a { display: block; height: 53px; line-height: 54px; background: #BA4C38; font-size: 28px; text-align: center; box-shadow: 2px 2px 2px gray; border-radius: 5px; color: white; transition: 0.5s; }
@media only screen and (max-width: 1000px) { .btn, .footer__nav a { font-size: 18px; } }
.btn:hover, .footer__nav a:hover { background: #f2ac48; }
.btn:active, .footer__nav a:active { transform: scale(0.95); }
.btn_small { width: 250px; }
@media only screen and (max-width: 1000px) { .btn_small { width: 200px; } }
.btn_center { margin: 15px auto; }

.footer { background: linear-gradient(to bottom, #4D4D4D 0, #404040, #4D4D4D 100%); padding-top: 30px; padding-bottom: 10px; min-width: 768px; }
.footer__nav { display: flex; flex-wrap: wrap; justify-content: center; align-items: center; margin-bottom: 15px; }
.footer__nav li { color: white; font-size: 12px; margin-right: 15px; }
.footer__nav li:last-of-type { margin-right: 0; }
.footer__nav li:last-of-type:after { display: none; }
.footer__nav a { box-shadow: none; height: auto; line-height: 1; font-size: 14px; padding: 5px 10px; margin-bottom: 10px; }
@media only screen and (max-width: 1000px) { .footer__nav a { font-size: 14px; } }
.footer small { font-size: 12px; color: white; display: block; text-align: center; margin: 3px 0; }
.footer__logo { float: left; margin-right: 50px; }

.block { *zoom: 1; border-radius: 5px; overflow: hidden; padding: 20px 15px; background: lightgray; background: rgba(211, 211, 211, 0.8); margin-top: 20px; }
.block:after { content: " "; display: table; clear: both; }
.block__content { width: 70%; padding: 10px 15px 20px 15px; border: 2px dashed #4D4D4D; float: right; border-radius: 5px; }
.block p { color: black; text-shadow: none; }
.block h2 { color: #4D4D4D; text-shadow: none; margin-top: 15px; border-bottom: none; }
.block h2:first-of-type { margin-top: 0; }
.block ul li { color: black; text-shadow: none; }

.sidebar { float: left; width: 30%; padding-right: 15px; }
.sidebar p { margin-top: 0; }
.sidebar__banner { display: block; box-shadow: 2px 2px 2px gray; border-radius: 5px; overflow: hidden; }
.sidebar__banner img { max-width: 100%; display: block; }
.sidebar__rating { margin-top: 15px; box-shadow: 2px 2px 2px gray; border-radius: 5px; overflow: hidden; }
.sidebar__rating img { max-width: 100%; display: block; }
.sidebar__rating-bottom { padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #4D4D4D; background: white; *zoom: 1; }
.sidebar__rating-bottom:after { content: " "; display: table; clear: both; }
.sidebar__rating-left, .sidebar__rating-right { float: left; text-align: center; }
.sidebar__rating-left { width: 60%; padding-top: 13px; }
.sidebar__rating-left span { display: inline-block; background-image: url("../img/icons.png"); width: 20px; height: 18px; background-position: -85px -118px; }
.sidebar__rating-right { width: 40%; border-left: 1px solid #4D4D4D; }
.sidebar__rating-right span { font-size: 30px; color: #4D4D4D; }
.sidebar__btn { margin-top: 15px; margin-bottom: 15px; }
.sidebar__top { box-shadow: 2px 2px 2px gray; border-radius: 5px; overflow: hidden; background: white; }
.sidebar__top-intro { background: #4D4D4D; color: white; font-size: 16px; text-align: center; padding: 5px 0; border-top-left-radius: 5px; border-top-right-radius: 5px; }
.sidebar__top-item { margin-top: 5px; padding-right: 5px; padding-left: 5px; *zoom: 1; }
.sidebar__top-item:after { content: " "; display: table; clear: both; }
.sidebar__top-item:last-of-type { border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; padding-bottom: 5px; }
.sidebar__top-item img { display: block; float: left; max-width: 130px; width: 100%; }
@media only screen and (max-width: 1000px) { .sidebar__top-item img { float: none; margin: 0 auto; max-width: 100%; } }
.sidebar__top-item div { float: right; }
@media only screen and (max-width: 1000px) { .sidebar__top-item div { float: none; text-align: center; padding: 0 20px; } }
.sidebar__top-item div strong { display: block; margin-top: 5px; margin-bottom: 5px; font-size: 14px; color: #4D4D4D; }
.sidebar__top-item div span { display: inline-block; vertical-align: middle; background-image: url("../img/icons.png"); width: 15px; height: 14px; background-position: -130px -118px; }
.sidebar__top-item div span:first-of-type { margin-left: 5px; }
.sidebar__top-item div p { margin-bottom: 5px; }
.sidebar__top-item div a { text-decoration: underline; color: #BA4C38; float: left; display: inline-block; margin: 0 3px; font-size: initial; }
.sidebar__top-item div a:last-of-type { float: right; }


