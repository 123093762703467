$icons: (0:0)
$icons: map-merge($icons,(arr-down: (X: -83px, Y:-56px, W: 25px, H: 21px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(close: (X: 0px, Y:-84px, W: 30px, H: 30px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(ecogra: (X: 0px, Y:-56px, W: 79px, H: 24px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(fb: (X: -67px, Y:-84px, W: 25px, H: 25px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(gambleaware: (X: 0px, Y:0px, W: 114px, H: 24px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(gamecare: (X: 0px, Y:-118px, W: 28px, H: 31px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(gb-flag: (X: -32px, Y:-118px, W: 25px, H: 18px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(goo: (X: -125px, Y:-84px, W: 25px, H: 25px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(gt: (X: -118px, Y:-45px, W: 39px, H: 32px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(half-star: (X: -61px, Y:-118px, W: 20px, H: 18px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(igc: (X: 0px, Y:-28px, W: 110px, H: 24px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(like-2: (X: -34px, Y:-84px, W: 29px, H: 29px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(like: (X: -118px, Y:0px, W: 41px, H: 41px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(money: (X: -109px, Y:-118px, W: 17px, H: 17px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(star-small: (X: -130px, Y:-118px, W: 15px, H: 14px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(star: (X: -85px, Y:-118px, W: 20px, H: 18px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))
$icons: map-merge($icons,(tw: (X: -96px, Y:-84px, W: 25px, H: 25px, TW: 159px, TH: 149px, IMG: '../img/icons.png')))


// Gets an attribute from the sass map
@function icon-attr($icon, $attr)
	$icon: map-get($icons, $icon)
	@return map-get($icon, $attr)

@mixin sprite($iconName)
	background-image: url(icon-attr($iconName, IMG))
	width: icon-attr($iconName, W)
	height: icon-attr($iconName, H)
	background-position: icon-attr($iconName, X) icon-attr($iconName, Y)
@mixin sprite-position($iconName)
	background-position: icon-attr($iconName, X) icon-attr($iconName, Y)
@mixin sprite-retina($iconName)
	background-image: url(icon-attr($iconName, IMG))
	$width: icon-attr($iconName, W)
	$height: icon-attr($iconName, H)
	width: $width/2
	height: $height/2
	$x: icon-attr($iconName, X)
	$y: icon-attr($iconName, Y)
	background-position: $x/2 $y/2
	$tw: icon-attr($iconName, TW) 	
	$th: icon-attr($iconName, TH)
	background-size: $tw/2 $th/2  	


@mixin s($i)
	+sprite($i)
@mixin sp($i)
	+sprite-position($i)
@mixin sr($i)
	+sprite-retina($i)	

//
//.icon-arr-down
//	width: 25px
//	height: 21px
//	background-image: url('../img/icons.png')
//	background-position: -83px -56px
//
//.icon-close
//	width: 30px
//	height: 30px
//	background-image: url('../img/icons.png')
//	background-position: 0px -84px
//
//.icon-ecogra
//	width: 79px
//	height: 24px
//	background-image: url('../img/icons.png')
//	background-position: 0px -56px
//
//.icon-fb
//	width: 25px
//	height: 25px
//	background-image: url('../img/icons.png')
//	background-position: -67px -84px
//
//.icon-gambleaware
//	width: 114px
//	height: 24px
//	background-image: url('../img/icons.png')
//	background-position: 0px 0px
//
//.icon-gamecare
//	width: 28px
//	height: 31px
//	background-image: url('../img/icons.png')
//	background-position: 0px -118px
//
//.icon-gb-flag
//	width: 25px
//	height: 18px
//	background-image: url('../img/icons.png')
//	background-position: -32px -118px
//
//.icon-goo
//	width: 25px
//	height: 25px
//	background-image: url('../img/icons.png')
//	background-position: -125px -84px
//
//.icon-gt
//	width: 39px
//	height: 32px
//	background-image: url('../img/icons.png')
//	background-position: -118px -45px
//
//.icon-half-star
//	width: 20px
//	height: 18px
//	background-image: url('../img/icons.png')
//	background-position: -61px -118px
//
//.icon-igc
//	width: 110px
//	height: 24px
//	background-image: url('../img/icons.png')
//	background-position: 0px -28px
//
//.icon-like-2
//	width: 29px
//	height: 29px
//	background-image: url('../img/icons.png')
//	background-position: -34px -84px
//
//.icon-like
//	width: 41px
//	height: 41px
//	background-image: url('../img/icons.png')
//	background-position: -118px 0px
//
//.icon-money
//	width: 17px
//	height: 17px
//	background-image: url('../img/icons.png')
//	background-position: -109px -118px
//
//.icon-star-small
//	width: 15px
//	height: 14px
//	background-image: url('../img/icons.png')
//	background-position: -130px -118px
//
//.icon-star
//	width: 20px
//	height: 18px
//	background-image: url('../img/icons.png')
//	background-position: -85px -118px
//
//.icon-tw
//	width: 25px
//	height: 25px
//	background-image: url('../img/icons.png')
//	background-position: -96px -84px
//